<template>
  <section>
    <h2 class="uppercase lg:pl-28 pl-4 pt-4 text-gray-600">
      Sie sind hier / <a style="color: #f27405" href="/home">Beroma Gmbh</a> /
      Rollladen
    </h2>
    <article class="container mx-auto p-12 lg:p-32">
      <h2 class="text-6xl pb-10" style="color: #f27405">
        Rollladen kaufen bei BE-RO-MA in Berlin!
      </h2>
      <p class="pb-10 text-gray-600">
        Wenn Sie einen Rollladen kaufen möchten, sind Sie bei uns genau richtig!
        In Berlin-Tempelhof sind wir Ihr Ansprechpartner für diesen modernen
        Klassiker, der mit seinen Allround-Eigenschaften viel mehr ist als die
        Visitenkarte Ihres Hauses. Profitieren Sie von unserem ausgezeichneten
        Kundenservice inklusive Fachberatung und Montage vor Ort.
      </p>
      <span class="text-2xl xl:pl-10 pb-3 text-center" style="color: #033859">
        Was sind die Vorteile von Rollläden?
      </span>
      <div
        class="flex flex-col lg:flex-row pb-10 text-gray-600 justify-center leading-relaxed text-2xl"
      >
        <ul class="mx-2">
          <li class="flex flex-row">
            <div class="mx-1" style="color: #f27405">
              <i class="fas fa-check"></i>
            </div>
            <div>Wärme- und Sonnenschutz <br /></div>
          </li>
          <li class="flex flex-row">
            <div class="mx-1" style="color: #f27405">
              <i class="fas fa-check"></i>
            </div>
            <div>Wärmedämmung / Isolierung<br /></div>
          </li>
          <li class="flex flex-row">
            <div class="mx-1" style="color: #f27405">
              <i class="fas fa-check"></i>
            </div>
            <div>Witterungsschutz<br /></div>
          </li>
        </ul>
        <ul class="mx-2">
          <li class="flex flex-row">
            <div class="mx-1" style="color: #f27405">
              <i class="fas fa-check"></i>
            </div>
            <div>Sichtschutz<br /></div>
          </li>
          <li class="flex flex-row">
            <div class="mx-1" style="color: #f27405">
              <i class="fas fa-check"></i>
            </div>
            <div>Verbesserter Einrbuchschutz<br /></div>
          </li>
          <li class="flex flex-row">
            <div class="mx-1" style="color: #f27405">
              <i class="fas fa-check"></i>
            </div>
            <div>Schall- und Insektenschutz<br /></div>
          </li>
        </ul>
      </div>
      <p class="pb-10 text-gray-600">
        Wenn Sie einen Rollladen kaufen möchten, sollten Sie sich von uns in
        Berlin beraten lassen. Unsere Rollläden sehen nicht nur von außen gut
        aus, sondern glänzen auch durch ihre inneren Werte. Rollläden sind nicht
        nur Licht- und Sichtschutz, sondern schützen auch vor Witterung. Mit
        unseren qualitativ hochwertigen Rollläden machen Sie außerdem auch
        eventuellen Einbrechen (egal, ob Mensch oder Insekt) das Leben
        schwer.<br /><br />
        Aber nicht nur dies: Schallwellen werden abgedämpft und bieten Ihn so
        einen ruhigen und stressfreien Alltag. Wenn Sie Ihren Rollladen kaufen,
        profitieren Sie bei uns in Berlin nicht nur von einer breiten
        Angebotsauswahl an hochwertigen und langlebigen Markenprodukten. Wir
        legen Wert auf eine gute Beratung und fachgerechte Montage vor Ort.
        Besuchen Sie uns oder schreiben Sie uns per WhatsApp , wenn Sie Fragen
        haben.
        <contacticons></contacticons>
      </p>
      <div class="grid lg:grid-cols-2 gap-4 text-gray-600">
        <div class="flex flex-col lg:pl-14">
          <span class="text-2xl pb-3" style="color: #033859">
            Vorbaurollladen in Markenqualität kaufen – natürlich bei BE-RO-MA in
            Berlin!
          </span>
          <p class="pt-10 pb-10">
            Einen Vorbaurollladen montieren unsere Fachkräfte bei Ihnen– wie der
            Name schon sagt – vor Ihrem Fenster (in die Laibung) oder vor die
            Fassade. Bei BE-RO-MA in Berlin bieten wir Ihnen verschiedene auf
            Wunsch angepasste Ausführungen mit runden oder eckigen Kästen. Ein
            Vorbaurollladen ist bei Ihrem Neubau in Berlin ein Blickfang, der
            architektonische Akzente setzt, eignet sich aber auch hervorragend
            zum nachträglichen Einbau. Wir garantieren eine schnelle Montage und
            einfache Wartung. <br /><br />
            Der Einbau eines solchen Rollladen-Typs, den sie bei uns in
            verschiedenen Varianten kaufen können, empfiehlt sich für Ihre Wohn-
            oder Geschäftsräume in Berlin gleich in mehrfacher Hinsicht:
            Erhöhter Einbruchschutz, Einsparung von Heizenergie durch
            verbesserte Wärmedämmung sowie sehr gute Schallschutzeigenschaften
            sorgen für Sicherheit und mehr Wohn- und Arbeitsqualität.
            Informieren Sie sich zum Thema Vorbaurollladen bei uns in Berlin
            oder schreiben Sie uns eine E-Mail. Übrigens: Unsere Produkte sind
            made in Germany!
          </p>
        </div>
        <div class="flex flex-col lg:pl-14">
          <h3 class="text-2xl pb-3" style="color: #033859">
            Weniger CO2-Emissionen – Solarrollladen in Berlin
          </h3>
          <p class="pt-10 pb-10">
            Mit einem Solarrollladen werden Sie bei uns eine Rollladen-Variante
            kaufen, mit der Sie sich in Berlin umweltfreundlich und
            energiesparend ausstatten. Der Rollladen nutzt die natürliche Kraft
            der Sonne und für 90 % aller Fenster und Türen eine ideale Lösung.
            Die Stromversorgung wird durch einen Akku sichergestellt, der
            mittels Solarpanel mit Sonnenenergie aufgeladen wird. Damit
            verringern Sie nicht nur Ihren Stromverbrauch und damit auch
            CO2-Emissionen. Der Solarrollladen funktioniert über den Akkubetrieb
            auch bei einem Stromausfall noch einwandfrei. <br /><br />
            Ideal für Ihre Neubauvorhaben in Berlin, wenn Ihnen umweltgerechtes
            Bauen ein Anliegen ist. Die Installation ist unproblematisch: Der
            Solarrollladen wird von unseren Fachkräften in Berlin vor Ort samt
            Kasten und integrierter Technik montiert.
          </p>
        </div>
      </div>
      <div class="flex flex-col lg:pl-14">
        <h3 class="text-2xl pb-3" style="color: #033859">
          Rollgitter, Rolltor oder Garagentor kaufen Sie bei Ihrem Fachanbieter
          in Berlin
        </h3>
        <p class="pt-10 pb-10">
          Ein Garagentor, Rollgitter oder Rolltor kaufen Sie bei uns in Berlin
          mit dem besonderen Anspruch an Qualität, Sicherheit und eine
          attraktive Optik. Die Entscheidung für die richtige Variante richtet
          sich nach den Gegebenheiten vor Ort und Ihren individuellen
          Anforderungen. Ein elektrischer Antrieb mit automatischer Steuerung
          bietet vor allem bei größeren Garagentor-Maßen kraftsparenden Komfort.
          Wenn Sie ein Rolltor kaufen möchten, erhalten Sie von uns in Berlin
          zudem ein Produkt mit TÜV-geprüfter Einbruchshemmung. <br /><br />
          Wenn es draußen mal lauter ist und Sie Ihre Ruhe genießen wollen,
          bieten unsere Rollläden Ihnen nebenbei sogar auch einen super
          Schallschutz. So kann Ihnen der Lärm gar nichts mehr.<br /><br />
          Sie sind neugierig geworden? Ganz gleich welche Art Rollladen sie
          kaufen möchten, bei BEROMA in Berlin erhalten Sie eine hervorragende
          Fachberatung und optimalen Kundenservice. Rufen Sie uns einfach an!<br /><br />
          <span class="m-2 font-bold text-center" style="color: #f27405"
            >Mehr Information unter:</span
          >
          <contacticons></contacticons>
        </p>
      </div>
    </article>
  </section>
</template>
<script>
import contacticons from "../components/contact";
export default {
  name: "App",
  components: {
    contacticons,
  },
};
</script>
